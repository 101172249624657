import { render, staticRenderFns } from "./tuijian02.vue?vue&type=template&id=65f0bede&scoped=true&"
import script from "./tuijian02.vue?vue&type=script&lang=js&"
export * from "./tuijian02.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./tuijian02.vue?vue&type=style&index=0&id=65f0bede&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "65f0bede",
  "63a1551e"
  
)

export default component.exports