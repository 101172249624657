var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tuijian02"},[_c('p',{staticClass:"title"},[_vm._v("精彩推荐")]),_vm._v(" "),_vm._l((_vm.newtuijian),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"tuijianbox02",on:{"click":function($event){return _vm.fn1(item.id)}}},[_c('img',{staticClass:"boxleft",attrs:{"src":item.image !==undefined
                  ? item.image
                  : item.cat_name == '热点咨询'
                  ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF-web.png'
                  : item.cat_name == '商标知识'
                  ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E5%95%86%E6%A0%87%E7%9F%A5%E8%AF%86-web.png'
                  : item.cat_name == '政策法规'
                  ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E6%94%BF%E7%AD%96%E6%B3%95%E8%A7%84-web.png'
                  : 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%BB%8F%E5%85%B8%E6%A1%88%E4%BE%8B-web.png',"alt":item.image !==undefined
          ? item.title
          : _vm.state == 0
          ? '热点咨询'
          : _vm.state == 1
          ? '商标知识'
          : _vm.state == 2
          ? '政策法规'
          : '经典案例'}}),_vm._v(" "),_c('div',{staticClass:"tuijian02word"},[_c('p',{staticClass:"tuijian02word00"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('p',{staticClass:"tuijian02word01",domProps:{"innerHTML":_vm._s(_vm.removeHtmlStyle(item.content))}})])]),_vm._v(" "),_c('hr')])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }