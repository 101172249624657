<template>
  <div class="tuijian">
    <p class="title">精品商标推荐</p>
    <div class="box">
      <img class="tupian" src="../../imger/newPict.png" alt="问标网"/>
      <div class="bottom">
        <p><span>第35类</span><span>企英</span></p>
        <el-button class="botton">询价</el-button>
      </div>
    </div>
    <div class="box">
      <img class="tupian" src="../../imger/newPict.png" alt="问标网"/>
      <div class="bottom">
        <p><span>第35类</span><span>企英</span></p>
        <el-button class="botton">询价</el-button>
      </div>
    </div>
    <div class="box">
      <img class="tupian" src="../../imger/newPict.png" alt="问标网"/>
      <div class="bottom">
        <p><span>第35类</span><span>企英</span></p>
        <el-button class="botton">询价</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.tuijian {
  width: 100%;
  background-color: #fff;
  margin-top: 0.625rem /* 10px -> 0.625rem */;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .title{
    font-weight: bold;
    font-size: 1.0625rem /* 17px -> 1.0625rem */;
    color: #333;
    line-height: 2.8125rem /* 45px -> 2.8125rem */;
  }
  .box{
    width: 17.0625rem /* 273px -> 17.0625rem */;
    height: 11.25rem /* 180px -> 11.25rem */;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 0.625rem /* 10px -> 0.625rem */;
    border: 0.0625rem /* 1px -> 0.0625rem */solid #DDDDDD;
    .tupian{
      width: 15.5rem /* 248px -> 15.5rem */;
      height: 7.6875rem /* 123px -> 7.6875rem */;
    }
    .bottom{
      width: 15.5rem /* 248px -> 15.5rem */;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.9375rem /* 15px -> 0.9375rem */;
      color: #555;
      .botton{
        min-height: 1.6875rem /* 27px -> 1.6875rem */;
        height: 1.6875rem /* 27px -> 1.6875rem */;
        background-color: #E51F26;
        color: #fff;
        width: 3.75rem /* 60px -> 3.75rem */;
        line-height: 0px;
        padding: 0;
      }
    }

  }
  
}
</style>