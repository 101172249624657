<template>
  <div class="tuijian02">
    <p class="title">精彩推荐</p>
    <div v-for="(item,index) in newtuijian" :key="index">
      <div class="tuijianbox02" @click="fn1(item.id)">
        <img class="boxleft" :src="
                  item.image !==undefined
                    ? item.image
                    : item.cat_name == '热点咨询'
                    ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF-web.png'
                    : item.cat_name == '商标知识'
                    ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E5%95%86%E6%A0%87%E7%9F%A5%E8%AF%86-web.png'
                    : item.cat_name == '政策法规'
                    ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E6%94%BF%E7%AD%96%E6%B3%95%E8%A7%84-web.png'
                    : 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%BB%8F%E5%85%B8%E6%A1%88%E4%BE%8B-web.png'
                "
                :alt="
          item.image !==undefined
            ? item.title
            : state == 0
            ? '热点咨询'
            : state == 1
            ? '商标知识'
            : state == 2
            ? '政策法规'
            : '经典案例'
        "
                />
        <div class="tuijian02word">
          <p class="tuijian02word00">{{item.title}}</p>
          <p class="tuijian02word01" v-html="removeHtmlStyle(item.content)"></p>
        </div>
      </div>
      <hr>  
    </div>    
  </div>
</template>

<script>
import { recommendNew } from "../../API/new";

// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'
export default {
  inject:['reload'],
  data(){
    return{
      newtuijian:[]
    }
  },
  created(){
    this.recommendNew()
  },
  methods:{
    fn1(data) {
      var UPID = [];
      for (let i = 0; i < this.newtuijian.length; i++) {
        let obj = { id: this.newtuijian[i].id, name: this.newtuijian[i].title };
        UPID[i] = obj;
      }
      sessionStorage.setItem("newsIdArray", JSON.stringify(UPID));
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify({newId:data}),keys)+'.html';
      
      const { href } = this.$router.resolve({
        path: `/newInfo/${sign}`
      });
      window.open(href, "_blank");
      this.reload();
    },
    async recommendNew(){
       const res = await recommendNew({
        key: sessionStorage.getItem("key"),
        platform:'问标'
      });
      if (res.data.key) {
        await sessionStorage.setItem("key", res.data.key);
        this.newtuijian = res.data.data;
      }
    },
    removeHtmlStyle(html) {
      let rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
      let newHtml = "";
      if (html) {
        newHtml = html.replace(rel, "");
      }
      return newHtml;
    },
  }


}
</script>

<style scoped lang="scss">
hr{
  width: 100%;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  background-color: #DDDDDD;
  border: none;
}
.tuijian02 {
  width: 100%;
  background-color: #fff;
  margin-top: 0.9375rem /* 15px -> 0.9375rem */;
  padding: 0.625rem /* 10px -> 0.625rem */;
  box-sizing: border-box;
  cursor: pointer;
  .title{
    font-size: 1.0625rem /* 17px -> 1.0625rem */;
    color: #333333;
    font-weight: bold;
    line-height: 2.5rem /* 40px -> 2.5rem */;
  }
  .tuijianbox02:hover{
    .tuijian02word{
      color: #4d89f1;
    }
  }
  .tuijianbox02 {
    width: 17.1875rem /* 275px -> 17.1875rem */;
    height: 4.0625rem /* 65px -> 4.0625rem */;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem /* 10px -> 0.625rem */;
    margin-top: 0.625rem /* 10px -> 0.625rem */;
    .boxleft{
      width: 7.3125rem /* 117px -> 7.3125rem */;
      height: 100%;
    }
    .tuijian02word{
      width: 9.375rem /* 150px -> 9.375rem */;
      height: 100%;
      font-size: 0.82125rem /* 13.14px -> 0.82125rem */;
      color: #333333;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tuijian02word00{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
      }
      .tuijian02word01{
        width: 100%;
        font-size: 0.704375rem /* 11.27px -> 0.704375rem */;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.25rem /* 20px -> 1.25rem */; //
        height: 2.5rem /* 40px -> 2.5rem */; ///* 2行隐藏就两倍，三行隐藏就三倍*/
      }
    }
  }
}
</style>