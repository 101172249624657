<template>
  <div class="phone">
    <p class="title">商标咨询热线</p>
    <p class="number">400-850-8819</p>
    <div class="orbox">
      <p class="or"></p>
      <p class="orword">或</p>
      <p class="or"></p>
    </div>
    <el-input
      v-model="inputData"
      placeholder="请输入联系电话"
      class="phoneinput"
    />
    <el-button class="phonebotton" @click="fn1()">提交并立即联系我们</el-button>
  </div>
</template>

<script>
import { message } from "../../API/index";
export default {
  data() {
    return {
      inputData: "",
      userID: "",
      userkey: "",
      messagedata: {
        user_nickname: "",
        user_name: "",
        user_phone: "",
        user_id: 0,
        message: "",
        source: "新闻资讯",
        advisories: "",
        reg_id: "",
        reg_type: "",
        platform: "问标",
        brand_num: "",
      },
    };
  },
  methods: {
    async fn1() {
      if (sessionStorage.getItem("userkey")) {
        this.userID = JSON.parse(localStorage.getItem("user"));
        this.userkey = sessionStorage.getItem("userkey");
        this.messagedata.user_id = this.userID.id;
        this.messagedata.user_phone = this.userID.phone;
        this.messagedata.user_nickname = this.userID.name;
      } else {
        this.userkey = sessionStorage.getItem("key");
      }
      if (this.inputData !== "") {
        const res = await message({
          key: this.userkey,
          phone: this.inputData,
          user_id: this.userID == "" ? 0 : this.userID.id,
          message: JSON.parse(JSON.stringify(this.messagedata)),
        });
        if (res.data.key) {
          this.$message({
            type: "success",
            message: "信息提交成功，等待专员联系",
          });
          this.inputData = "";
        }
      }else{
        this.$message({
            type: "info",
            message: "请填写信息",
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.phone {
  width: 100%;
  height: 16.95625rem /* 271.3px -> 16.95625rem */;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .title {
    font-size: 1.05625rem /* 16.9px -> 1.05625rem */;
    color: #333333;
  }
  .number {
    font-size: 1.408125rem /* 22.53px -> 1.408125rem */;
    color: #e51f26;
  }
  .orbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .or {
      width: 6.25rem /* 100px -> 6.25rem */;
      height: 0.0625rem /* 1px -> 0.0625rem */;
      background-color: #dddddd;
    }
    .orword {
      font-size: 0.9375rem /* 15px -> 0.9375rem */;
      color: #999999;
    }
  }
  .phoneinput {
    width: 15.625rem /* 250px -> 15.625rem */;
    height: 3.125rem /* 50px -> 3.125rem */;
  }
  .phonebotton {
    width: 15.625rem /* 250px -> 15.625rem */;
    height: 3.125rem /* 50px -> 3.125rem */;
    background-color: #4d89f1;
    color: #fff;
  }
}
</style>